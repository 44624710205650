import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { GSAdminLocale, SchoolLocale } from "@app/locales/localeid";
import { Modal, InputNumber, Select, Form, Spin, Radio } from 'antd-min';
import { GLGlobal, GLForm, FormItemsLayout, GLFormComponentProps, FormHelper, PaginationParams, MessageHelper, HttpStatusCode } from 'gl-commonui';
import { SubmitBtns } from '@app/components';
import { lazyInject, TYPES, CommonHelper, PurchaseTarget, isFutureDate, GSSchoolAction, ProductType, fmtMsg, MIN_UNIT, LITTLE_SEED_MAX_UNIT } from '@app/util';
import { RequestProductItemModel, RequestProductItemPropsModel, ISubscriptionMaterialService } from '@app/service/material';
import { ProductModel, IProductService } from '@app/service/school/product';
import { SchoolClassModel, ISchoolClassService, CurriculumType, SubscriptionType } from '@app/service/class';
import { processError } from '@app/states/material';
import { ICampusService } from '@app/service/school/campus';
import { isNull, isUndefined } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';

export interface ProductDataParams {
    schoolId: string
    campusId?: string
    orderId?: string
    class?: SchoolClassModel
    subscriptionType: SubscriptionType
}

export interface ProductModalProps {
    modalVisible?: boolean;
    modalTitle?: string;
    startUnit?: number;
    maxUnit?: number;
    requestItem?: RequestProductItemModel;
    purchaseTarget: PurchaseTarget;
    dataParams: ProductDataParams;
    isSaving?: boolean;
    isCreate?: boolean;
    isOnlyNoteEdit?: boolean;
    onSubmit?: (product) => void;
    onCancel?: () => void;
}

interface ProductModalStates {
    loadingData?: boolean
    classOptions?: JSX.Element[] | null
    classes?: SchoolClassModel[]
    currentClass?: SchoolClassModel
    requestItemId?: string
    // licenseTypeCheckVisible?: boolean
    unitOptions?: JSX.Element[] | null
    productOptions?: JSX.Element[] | null
    products?: ProductModel[]
    //productSubTotal?: string
    productSubTotal4Display?: string
    canAddProduct?: boolean
    productType: ProductType
}

const SchoolClass = {
    'Empty': { id: null, name: fmtMsg({ id: GSAdminLocale.ProductClassNotSet }) }
};

const SchoolUnit = {
    'NotSet': { id: 0, name: 'Not Set' }
};

const EmptySubTotal = '';

class LicenseTypeCheckGroup extends Component<{ disabled: boolean, onChange: (value) => void }> {
    render() {
        return (
            <Radio.Group {...this.props}>
                {Object.getOwnPropertyNames(CurriculumType)
                    .filter(type => !isNaN(parseInt(type, 10)))
                    .map((type, index) => <Radio key={index} value={parseInt(type, 10)}>{CurriculumType[type]}</Radio >)
                }
            </Radio.Group>
        )
    }
}

@GLForm.create()
export class ProductModal extends Component<ProductModalProps & GLFormComponentProps, ProductModalStates> {
    @lazyInject(TYPES.IProductService)
    productService: IProductService
    @lazyInject(TYPES.ISchoolClassService)
    classService: ISchoolClassService
    @lazyInject(TYPES.ISubscriptionMaterialService)
    subscriptionService: ISubscriptionMaterialService
    @lazyInject(TYPES.ICampusService)
    campusService: ICampusService

    campusModel
    constructor(props) {
        super(props);
        this.state = {
            loadingData: false,
            canAddProduct: true,
            productType: ProductType.General
        };
        this.handleClassChanged = this.handleClassChanged.bind(this);
        // this.handleLicenseTypeChanged = this.handleLicenseTypeChanged.bind(this);
        this.handleUnitChanged = this.handleUnitChanged.bind(this);
        this.handleProductChanged = this.handleProductChanged.bind(this);
        this.handleQuantityChanged = this.handleQuantityChanged.bind(this);
        this.isQuantityValid = this.isQuantityValid.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onProductTypeChange = this.onProductTypeChange.bind(this);
        this.isFromSubscription = this.isFromSubscription.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { purchaseTarget, modalVisible } = this.props;
        const productType = nextProps.requestItem && !isUndefined(nextProps.requestItem.productType) && !isNull(nextProps.requestItem.productType) ? nextProps.requestItem.productType : this.state.productType;
        let isToLoadProducts = true;
        const needDefaultProduct = nextProps.requestItem && (nextProps.requestItem.id != null
            || nextProps.purchaseTarget == PurchaseTarget.Class || nextProps.purchaseTarget == PurchaseTarget.ClassSchool);
        if (nextProps.requestItem && needDefaultProduct && nextProps.modalVisible && nextProps.modalVisible != modalVisible) {
            let unit = nextProps.requestItem.unit;
            if (this.handlePurchaseOtherType() && productType === ProductType.General) {
                unit = SchoolUnit.NotSet.id;
            }
            this.handleUnitChanged(unit, needDefaultProduct, nextProps.dataParams.class, nextProps.requestItem.productType);
            isToLoadProducts = false;
        }
        if (purchaseTarget != PurchaseTarget.Class
            && purchaseTarget != PurchaseTarget.ClassSchool
            && nextProps.modalVisible && nextProps.modalVisible != modalVisible
            && !this.state.classes) {
            this.campusService.get(nextProps.dataParams).then(d => {
                this.campusModel = d;
                this.getClasses(nextProps, isToLoadProducts);
            });
        }
        if ((purchaseTarget == PurchaseTarget.Class || purchaseTarget == PurchaseTarget.ClassSchool)
            && nextProps.modalVisible && !this.state.unitOptions) {
            this.setUnitOptions(productType);
        }
        if (nextProps.modalVisible != modalVisible) {
            if (this.handlePurchaseOtherType() && productType === ProductType.General) {
                // do nothing
            }
            else {
                if (!nextProps.requestItem.id) {
                    this.setState({ productOptions: null });
                }
                this.setState({
                    //productSubTotal: EmptySubTotal,
                    productSubTotal4Display: EmptySubTotal
                });
            }
            if (!nextProps.modalVisible) {
                this.setState({ classes: null, currentClass: null, unitOptions: null, canAddProduct: true, productType: ProductType.General });
            }
        }
    }

    getClasses(nextProps, isToLoadProducts) {
        this.setState({ loadingData: true });
        const { dataParams: { schoolId, campusId }, requestItem, purchaseTarget, isCreate } = nextProps;
        this.classService.getItemsBy({ campusId: campusId, sortBy: "name", isDescending: false }, { schoolId: schoolId }).then((classes) => {
            let list = classes.data.schoolClasses.filter(schoolClass => isCreate && this.campusModel.annualPrepComplete && !GLGlobal.isActionValid(GSSchoolAction.IgnoreCampusAnnualPrepComplete) ? !isFutureDate(schoolClass.startDate) : true).filter((schoolClass) => !schoolClass.disabled || (schoolClass.disabled && requestItem.classId && schoolClass.id == requestItem.classId)).map((schoolClass) => {
                schoolClass.currentUnit = Math.abs(schoolClass.currentUnit);
                schoolClass.maxUnit = Math.abs(schoolClass.maxUnit);
                schoolClass.nextUnit = Math.abs(schoolClass.nextUnit);
                return schoolClass;
            });

            if (purchaseTarget == PurchaseTarget.School) list.unshift({ id: SchoolClass.Empty.id, name: SchoolClass.Empty.name });
            const currentClass = list.find((schoolClass) => requestItem && schoolClass.id == requestItem.classId && schoolClass.id != SchoolClass.Empty.id);

            const productType = requestItem && !isUndefined(requestItem.productType) && !isNull(requestItem.productType) ? requestItem.productType : this.state.productType;

            if (purchaseTarget == PurchaseTarget.School || purchaseTarget == PurchaseTarget.SchoolClass) {
                currentClass ?
                    this.setClassUnitOptions(currentClass, productType) : this.setUnitOptions(productType)
                // this.setUnitOptions((!requestItem.unit || requestItem.unit > 0) ? LicenseType.GrapeSEED : LicenseType.LittleSEED);
            }
            this.setState({
                loadingData: false,
                classes: list,
                productType: productType,
                // licenseTypeCheckVisible: list.filter(schoolClass => schoolClass.licenseType == LicenseType.LittleSEED).length > 0,
                classOptions: this.getClassOptions(list, productType, purchaseTarget == PurchaseTarget.School),
                currentClass: currentClass,
                requestItemId: null
            });

            if (this.handlePurchaseOtherType() && isToLoadProducts && productType === ProductType.General) {
                this.handleUnitChanged(SchoolUnit.NotSet.id, null, null, productType);
            }
            else {
                if (!requestItem || !requestItem.id) {
                    this.setState({ productOptions: null });
                    if (purchaseTarget == PurchaseTarget.SchoolClass) {
                        this.setState({ unitOptions: null });
                    }
                }
            }
        }).catch((response) => {
            console.log(response);
            this.setState({ loadingData: false });
        });
    }

    getClassOptions(classes: any[], productType: ProductType, hasFirstNull: boolean) {
        let classesToRender = [];

        if (classes && classes.length > 0) {
            if (!this.isFromSubscription()) {
                if (productType === ProductType.General) {
                    classesToRender = classes;
                }
                else if (productType === ProductType.GrapeSeed) {
                    classesToRender = classes.filter(x => x.curriculumType == CurriculumType.GrapeSEED);
                }
                else if (productType === ProductType.LittleSeed) {
                    classesToRender = classes.filter(x => x.curriculumType == CurriculumType.LittleSEED);
                }

                if (hasFirstNull && (productType === ProductType.GrapeSeed || productType === ProductType.LittleSeed)) {
                    classesToRender.unshift(classes[0]);
                }
            }
            else {
                classesToRender = classes;
            }

        }

        return classesToRender.map((schoolClass, index) => {
            return CommonHelper.getOption(schoolClass.id, schoolClass.id, schoolClass.name)
        });
    }

    setUnitOptions(productType: ProductType) {
        const { startUnit, maxUnit, dataParams: { class: schoolClass } } = this.props;

        let unitOptions = [];

        if (this.handlePurchaseOtherType() && productType === ProductType.General) {
            unitOptions.push(this.getNotSetUnitOption());
        }
        else {
            unitOptions.push(this.getNotSetUnitOption());
            const isLittleSeed = (productType == ProductType.LittleSeed || schoolClass && parseInt(schoolClass.curriculumType, 10) == CurriculumType.LittleSEED);
            const isGrapeSeed = (productType == ProductType.GrapeSeed || schoolClass && parseInt(schoolClass.curriculumType, 10) == CurriculumType.GrapeSEED);

            if (isLittleSeed) {
                unitOptions.push(this.getUnitOptions(MIN_UNIT, LITTLE_SEED_MAX_UNIT, true));
            }
            else if (isGrapeSeed && startUnit) {
                unitOptions.push(this.getUnitOptions(startUnit, maxUnit, false));
            }
        }

        this.setState({ unitOptions: unitOptions });
    }

    getUnitOptions(minUnit: number, maxUnit: number, isLittleSeed: boolean) {
        let appendToKey = isLittleSeed ? "ls" : "gs";
        return [...Array(maxUnit).keys()].filter((unit) => unit >= minUnit - 1).map((unitKey, index) => {
            return CommonHelper.getOption(index + 1 + appendToKey, this.getUnitValue(unitKey + 1, isLittleSeed), this.getUnitText(unitKey + 1, isLittleSeed))
        });
    }
    getUnitValue(unit: number, isLittleSeed: boolean) {
        return isLittleSeed ? -Math.abs(unit) : unit;
    }

    getUnitText(unit: number, isLittleSeed: boolean) {
        return isLittleSeed ? `LS ${unit}` : `GS ${unit}`;
        //return unit; //isLittleSeed? `LittleSEED ${unit}` : unit;
    }

    getNotSetUnitOption() {
        const notSetText = GLGlobal.intl.formatMessage({ id: SchoolLocale.MaterialRequestItemModalUnitNotSet });
        return [<Select.Option key={0} value={SchoolUnit.NotSet.id}>{notSetText}</Select.Option>]
    }

    getProductOptions(products) {
        return products && products.map((product, index) => {
            return CommonHelper.getOption(product.id, product.id, product.name)
        });
    }

    setClassUnitOptions(schoolClass: SchoolClassModel, productType: ProductType) {
        if (this.handlePurchaseOtherType() && productType === ProductType.General) {
            this.setState({
                unitOptions: this.getNotSetUnitOption()
            });
        }
        else {
            const licenseTypeN = parseInt(schoolClass.curriculumType, 10);
            const currentUnit = schoolClass.currentUnit ? schoolClass.currentUnit : schoolClass.startUnit;
            const startUnit = licenseTypeN == CurriculumType.GrapeSEED ? currentUnit : 1;
            const maxUnit = licenseTypeN == CurriculumType.GrapeSEED ?
                schoolClass.nextUnit ? schoolClass.nextUnit : currentUnit :
                8;
            this.setState({
                unitOptions: this.getNotSetUnitOption().concat(startUnit ?
                    this.getUnitOptions(startUnit, maxUnit, licenseTypeN == CurriculumType.LittleSEED) :
                    [])
            });
        }
    }

    getDefaultQuantity(schoolClass: SchoolClassModel) {
        return schoolClass.studentCount;
    }
    getLicenseQuantity(schoolClass) {
        return schoolClass.newStudentCount;
    }

    setUnitValNotSet(unitValToSet: any, productType) {
        let val = unitValToSet;
        let retValue = false;
        if (this.handlePurchaseOtherType() && productType === ProductType.General) {
            val = SchoolUnit.NotSet.id;
            retValue = true;
        }
        this.props.form.setFieldsValue({ unit: val });
        return retValue;
    }

    onProductTypeChange(event) {
        const productTypeVal = event.target.value;
        let quantityVal = null;
        let classIdVal = null;
        let maxUnit = productTypeVal === ProductType.General ? SchoolUnit.NotSet.id : null;
        let currentClass: any = null;

        if (!this.isClassSelectorVisible()) {
            if (this.state.currentClass) {
                currentClass = this.state.currentClass;
                quantityVal = this.getDefaultQuantity(currentClass);
            } else if (this.props.dataParams.class) {
                currentClass = this.props.dataParams.class;
                quantityVal = this.getLicenseQuantity(currentClass);
            }

            if(currentClass){
                classIdVal = currentClass.id;
                maxUnit = currentClass.nextUnit && productTypeVal !== ProductType.General ? this.getUnitValue(currentClass.nextUnit,
                    parseInt(currentClass.curriculumType, 10) == CurriculumType.LittleSEED) : SchoolUnit.NotSet.id;
            }

            this.props.form.setFieldsValue({ quantity: quantityVal, productId: null });
        }
        else
        {
            this.props.form.setFieldsValue({ quantity: quantityVal, productId: null, classId: classIdVal });
            this.setState({
                classOptions: this.getClassOptions(this.state.classes, productTypeVal, this.props.purchaseTarget == PurchaseTarget.School),
                currentClass: currentClass
            });
        }

        this.setUnitValNotSet(maxUnit, productTypeVal);
        this.setUnitOptions(productTypeVal);


        if (!this.isClassSelectorVisible() || productTypeVal === ProductType.General) {
            this.handleUnitChanged(maxUnit, null, currentClass, productTypeVal);
        }
        else {
            this.setState({
                productOptions: null,
                products: [],
                //productSubTotal: EmptySubTotal,
                productSubTotal4Display: EmptySubTotal,
                //classOptions: this.getClassOptions(this.state.classes, productTypeVal, this.props.purchaseTarget == PurchaseTarget.School),
                //currentClass: null,
                productType: productTypeVal
            });
        }
    }

    handleClassChanged(classId) {
        const { dataParams: { schoolId, campusId, orderId }, form, purchaseTarget } = this.props;
        const currentClass = this.state.classes.find((schoolClass) => schoolClass.id == classId && schoolClass.id != SchoolClass.Empty.id);
        this.setState({ currentClass: currentClass ? currentClass : null });
        purchaseTarget == PurchaseTarget.School && form.setFieldsValue({ licenseType: currentClass ? parseInt(currentClass.curriculumType, 10) : CurriculumType.GrapeSEED });
        const maxUnit = currentClass && currentClass.nextUnit ? this.getUnitValue(currentClass.nextUnit,
            parseInt(currentClass.curriculumType, 10) == CurriculumType.LittleSEED) :
            SchoolUnit.NotSet.id;
        currentClass ? this.setClassUnitOptions(currentClass, this.state.productType) : this.setUnitOptions(this.state.productType);
        const needLoadingData = currentClass && currentClass.id != SchoolClass.Empty.id;
        if (needLoadingData) {
            this.setState({ loadingData: needLoadingData });
            this.subscriptionService.getSubscriptionCart({ schoolId, campusId, classId, orderId }).then((subscriptionPurchaseRequest) => {
                this.setState({
                    loadingData: false,
                    requestItemId: subscriptionPurchaseRequest.subscriptionRequestDetails.id,
                    productOptions: null,
                    canAddProduct: true
                });
                const retValue = this.setUnitValNotSet(maxUnit, this.state.productType);
                form.setFieldsValue({ quantity: currentClass ? this.getDefaultQuantity(currentClass) : null });
                this.handleUnitChanged(retValue ? SchoolUnit.NotSet.id : maxUnit);
            }).catch((response) => {
                //console.log(response);
                this.setState({
                    loadingData: false,
                    productOptions: null,
                    canAddProduct: false
                });
                const error = response.statusCode == HttpStatusCode.BadRequest && response.text ? JSON.parse(response.text) : null
                processError(error);
            });
        }
        else {
            this.setState({ requestItemId: null, productOptions: null, products: [], productSubTotal4Display: EmptySubTotal });
            this.setUnitValNotSet(null, this.state.productType);
            form.setFieldsValue({ quantity: null, productId: null });
        }
    }

    // handleLicenseTypeChanged(e) {
    //     this.props.form.setFieldsValue({ unit: null, quantity: null, productId: null });
    //     this.setState({
    //         productOptions: null,
    //         products: [],
    //         //productSubTotal: EmptySubTotal,
    //         productSubTotal4Display: EmptySubTotal
    //     });
    //     this.setUnitOptions(e.target.value);
    // }

    isFromSubscription() {
        return this.props.purchaseTarget == PurchaseTarget.Class || this.props.purchaseTarget == PurchaseTarget.SchoolClass;
    }

    handleUnitChanged(unit, needDefaultProduct?, schoolClass?, productType?) {
        let params: { schoolId: string, schoolClassId?: string, unit?: number, isSubscription?: boolean, type?: number }
            = {
            schoolId: this.props.dataParams.schoolId,
            unit: unit == SchoolUnit.NotSet.id ? null : unit,
            isSubscription: this.isFromSubscription(),
            type: this.isFromSubscription() ? null : (!isUndefined(productType) && !isNull(productType)) ? productType : this.state.productType
        };
        if (this.props.purchaseTarget == PurchaseTarget.Class) {
            params.schoolClassId = this.props.dataParams.class.id;
        }
        else if (this.props.purchaseTarget == PurchaseTarget.SchoolClass) {
            params.schoolClassId = this.state.currentClass ? this.state.currentClass.id : (schoolClass ? schoolClass.id : -1);
        }
        if (params.unit) {
            const schoolClass = this.props.purchaseTarget == PurchaseTarget.SchoolClass ? this.state.currentClass : (this.props.dataParams.class || this.state.currentClass);
            if (schoolClass && parseInt(schoolClass.curriculumType, 10) == CurriculumType.LittleSEED) {
                //there unit should set negative value to call back end api
                params.unit = -Math.abs(params.unit);
            }
        }
        if (needDefaultProduct != true) {
            this.props.form.setFieldsValue({ productId: null });
        }
        this.setState({ loadingData: true, productType: params.type });
        this.productService.getItemsBy(params).then((products) => {
            const product = (products as ProductModel[]).find(product => product.id == this.props.requestItem.productId);
            this.setState({
                loadingData: false,
                productOptions: this.getProductOptions(products),
                products: products as ProductModel[],
                //productSubTotal: needDefaultProduct? this.props.requestItem.subTotal : EmptySubTotal,
                productSubTotal4Display: needDefaultProduct && product ?
                    GLGlobal.intl.formatMessage({ id: SchoolLocale.MaterialPurchaseItemFieldPriceWithColon }, { price: CommonHelper.formatNumber(product.price), currency: product.currency })
                    : EmptySubTotal,
            });
        }).catch((response) => {
            //console.log(response);
            this.setState({
                loadingData: false,
                productOptions: null,
                products: [],
                //productSubTotal: EmptySubTotal,
                productSubTotal4Display: EmptySubTotal
            });
        });
    }

    handleProductChanged(productId) {
        let product = null;

        if (this.state.products) {
            product = this.state.products.find((p) => p.id == productId);
        }
        if (this.props.purchaseTarget == PurchaseTarget.School) {
            const count = parseInt(this.props.form.getFieldValue(RequestProductItemPropsModel.quantity), 10);
            this.computeSubTotal(product, count);
        }
        if (product) {
            if (!product.defaultQuantityToLicenseCount) {
                this.props.form.setFieldsValue({ [RequestProductItemPropsModel.quantity]: 1 });
            }
            else {
                let quantity = null;

                if (this.state.currentClass) {
                    quantity = this.getDefaultQuantity(this.state.currentClass);
                } else if (this.props.dataParams.class) {
                    quantity = this.getLicenseQuantity(this.props.dataParams.class);
                }

                this.props.form.setFieldsValue({ [RequestProductItemPropsModel.quantity]: quantity });
            }
        }
    }

    handleQuantityChanged(quantity) {
        if (this.props.purchaseTarget == PurchaseTarget.School && this.state.products) {
            const product = this.state.products.find((p) => p.id == this.props.form.getFieldValue(RequestProductItemPropsModel.productId));
            const count = parseInt(quantity, 10);
            this.computeSubTotal(product, count);
        }
    }

    computeSubTotal(product: ProductModel, count: number) {
        //const subTotal = product && count ? CommonHelper.FormatNumber(product.price * count) : EmptySubTotal;
        const subTotal4Display = product ? GLGlobal.intl.formatMessage({ id: SchoolLocale.MaterialPurchaseItemFieldPriceWithColon }, { price: product.price, currency: product.currency }) : EmptySubTotal;
        //const subTotal4Save = product && count ? `${product.currency}${subTotal}` : `${subTotal}`;
        this.setState({
            //productSubTotal: subTotal4Save,
            productSubTotal4Display: CommonHelper.formatNumber(subTotal4Display)
        });
    }

    isQuantityValid(form): boolean {
        if (!this.props.isOnlyNoteEdit) {
            const result = form.getFieldValue(RequestProductItemPropsModel.quantity) > 0;
            const error = GLGlobal.intl.formatMessage({ id: SchoolLocale.MaterialPurchaseItemFieldQuantityError });
            if (!result) { MessageHelper.ShowError({ error: error, error_description: error, error_code: error }); }
            return result;
        }

        return true;
    }

    handleSave(e) {
        e.preventDefault();
        const form = this.props.form;
        form.validateFields((err, values) => {
            if (!err && this.isQuantityValid(form)) {
                if (this.props.purchaseTarget == PurchaseTarget.SchoolClass) {
                    values.requestItemId = this.state.requestItemId || this.props.requestItem.requestId;
                    if (this.props.requestItem.id) { values.id = this.props.requestItem.id; }
                }
                if (values.unit == SchoolUnit.NotSet.id) {
                    values.unit = null;
                }
                const schoolClass = this.state.currentClass || this.props.dataParams.class;
                if ((values.unit && schoolClass && parseInt(schoolClass.curriculumType, 10) == CurriculumType.LittleSEED)
                    || (values.unit && !schoolClass && values.licenseType && values.licenseType == CurriculumType.LittleSEED)) {
                    //there unit should save negative value
                    values.unit = -Math.abs(values.unit);
                }
                if (this.props.purchaseTarget == PurchaseTarget.ClassSchool) {
                    values.classId = this.props.dataParams.class.id;
                }
                if (this.props.isOnlyNoteEdit) {
                    values.isOnlyNoteEdit = true;
                }
                this.props.onSubmit(values);
            }
        });
        if (this.props.isOnlyNoteEdit) {
            /*
                * Using stopPropagation since when on order page the event propagates to save method of order.tsx
                * since we only want to show change(note change for purchase item) locally on the grid.
            */
            e.stopPropagation();
        }
    }

    filterSelectText(input, option) {
        return (option.props.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }

    handlePurchaseOtherType() {
        return !this.isFromSubscription() && !this.props.isOnlyNoteEdit;
    }

    isClassSelectorVisible() {
        return this.props.purchaseTarget != PurchaseTarget.Class && this.props.purchaseTarget != PurchaseTarget.ClassSchool;
    }

    render() {
        const { form, modalVisible, modalTitle, requestItem, onCancel, purchaseTarget, dataParams, isOnlyNoteEdit } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const fmtMsg = GLGlobal.intl.formatMessage;
        const isSubscriptionModal = this.isFromSubscription();
        let unit = purchaseTarget != PurchaseTarget.Class && purchaseTarget != PurchaseTarget.ClassSchool ?
            (requestItem.id ? (requestItem.unit ? requestItem.unit : SchoolUnit.NotSet.id) : null) :
            (requestItem.unit ? requestItem.unit : SchoolUnit.NotSet.id);

        if (this.handlePurchaseOtherType() && this.state.productType === ProductType.General) {
            unit = SchoolUnit.NotSet.id;
        }

        if (!this.state.unitOptions) {
            unit = null;
        }

        const { getFieldDecorator } = form;
        const classSelectorVisible = this.isClassSelectorVisible();
        const classSelectorRequired = this.props.purchaseTarget == PurchaseTarget.SchoolClass;
        const defaultClass = requestItem.classId && this.state.classes && this.state.classes.find(c => c.id == requestItem.classId);
        const defaultLicenseType = requestItem.classId ?
            (defaultClass ? parseInt(defaultClass.curriculumType, 10) : null) :
            ((!requestItem.unit || requestItem.unit > 0) ? CurriculumType.GrapeSEED : CurriculumType.LittleSEED);
        if (dataParams && dataParams.class && !isNaN(unit)) { unit = this.getUnitValue(unit, parseInt(dataParams.class.curriculumType, 10) == CurriculumType.LittleSEED); }
        const classId = requestItem.id ? (this.state.classOptions && this.state.classOptions.length > 0 ? requestItem.classId : null) : null;
        const productId = requestItem.id ? (this.state.productOptions && this.state.productOptions.length > 0 ? requestItem.productId : null) : null;
        const disableGrapeseed = !classSelectorVisible && dataParams && dataParams.class && parseInt(dataParams.class.curriculumType, 10) == CurriculumType.LittleSEED;
        const disableLittleseed = !classSelectorVisible && dataParams && dataParams.class && parseInt(dataParams.class.curriculumType, 10) == CurriculumType.GrapeSEED;
        const product = this.state.products ? this.state.products.find((p) => p.id == this.props.form.getFieldValue(RequestProductItemPropsModel.productId)) : null;
        const quantity = form.getFieldValue("quantity");
        return (
            <Modal
                title={fmtMsg({ id: modalTitle })}
                closable={false}
                footer={null}
                visible={modalVisible}
                destroyOnClose={true}
                className={`product-modal product-modal-${this.props.purchaseTarget == PurchaseTarget.School ? 'school' : 'class'}`}
            >
                <Spin spinning={this.state.loadingData || this.props.isSaving}>
                    <GLForm form={form} onSubmit={this.handleSave}>
                        {!isOnlyNoteEdit && !isSubscriptionModal && <Form.Item>
                            <Radio.Group className="" value={this.state.productType} defaultValue={ProductType.General} onChange={this.onProductTypeChange} >
                                <Radio value={ProductType.General}>{fmtMsg({ id: GSAdminLocale.ProductProductTypeGeneral })}</Radio>
                                <Radio disabled={disableGrapeseed} value={ProductType.GrapeSeed}>{fmtMsg({ id: GSAdminLocale.ProductProductTypeGrapeseed })}</Radio>
                                <Radio disabled={disableLittleseed} value={ProductType.LittleSeed}>{fmtMsg({ id: GSAdminLocale.ProductProductTypeLittleseed })}</Radio>
                            </Radio.Group></Form.Item>}
                        {
                            !isOnlyNoteEdit && classSelectorVisible &&
                            renderFormItem(form, SchoolLocale.MaterialPurchaseItemFieldClass, "classId",
                                <Select showSearch={true} filterOption={this.filterSelectText} 
                                size="large" onChange={this.handleClassChanged}>{this.state.classOptions}</Select>,
                                classId, classSelectorRequired)
                        }
                        {/* {
                            this.state.licenseTypeCheckVisible && purchaseTarget == PurchaseTarget.School &&
                            <Form.Item>
                                {getFieldDecorator('licenseType', { initialValue: defaultLicenseType })(
                                    <LicenseTypeCheckGroup disabled={this.state.currentClass != null} onChange={this.handleLicenseTypeChanged} />)}
                            </Form.Item>
                        } */}
                        {!isOnlyNoteEdit && <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialPurchaseItemFieldUnit, RequestProductItemPropsModel.unit,
                                <Select size="large" onChange={this.handleUnitChanged}>{this.state.unitOptions}</Select>, unit, true)}
                        </FormItemsLayout>}
                        {!isOnlyNoteEdit && renderFormItem(form, SchoolLocale.MaterialPurchaseItemFieldProduct, RequestProductItemPropsModel.productId,
                            <Select showSearch={true} filterOption={this.filterSelectText} size="large" onChange={this.handleProductChanged}>{this.state.productOptions}</Select>,
                            productId, true)}
                        {!isOnlyNoteEdit && <FormItemsLayout colTotal={2}>
                            {renderFormItem(form, SchoolLocale.MaterialPurchaseItemFieldQuantity, RequestProductItemPropsModel.quantity,
                                <InputNumber size="large" min={0} max={9999} precision={0} onChange={this.handleQuantityChanged} />, requestItem.quantity, true)}
                            {this.props.purchaseTarget == PurchaseTarget.School && this.state.productSubTotal4Display !== EmptySubTotal &&
                                <div className="price-display">
                                    <div>{this.state.productSubTotal4Display}</div>
                                    {product && <div>{GLGlobal.intl.formatMessage(
                                        { id: SchoolLocale.MaterialPurchaseItemFieldProductTotal },
                                        { price: CommonHelper.formatNumber(CommonHelper.fixRounding(product.price * quantity, 2)), currency: product.currency }
                                    )}</div>}
                                </div>}
                        </FormItemsLayout>}
                        {!isSubscriptionModal && renderFormItem(form, SchoolLocale.MaterialPurchaseItemFieldNote, RequestProductItemPropsModel.note,
                            <TextArea maxLength={500} className="product-modal__note"></TextArea>, requestItem.note, false, []
                        )}
                        <Form.Item>
                            <SubmitBtns submitTitle={SchoolLocale.MaterialPurchaseItemModalSaveButton} isDisabled={!this.state.canAddProduct} onCancel={onCancel} />
                        </Form.Item>
                    </GLForm>
                </Spin>
            </Modal>
        )
    }
}
