import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as H from "history";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Icon, Table, Modal } from "antd-min";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ColumnProps, TableProps } from "antd/lib/table";
import { InputNumber } from "wijmo/wijmo.input";
import { ColumnLink, GridInputNumber, WijmoGrid } from "@app/components/grid";
import {
    GLGlobal,
    GLLocale,
    GLUtil,
    maskThrottle,
    unmaskThrottle,
    HttpStatusCode
} from "gl-commonui";
import { SchoolLocale } from "@app/locales/localeid";
import { PathConfig } from "@app/config/pathconfig";
import {
    GLIcon,
    GLEditableTable,
    GLEditableTableProps,
    GLColumnProps,
    EditableCellDatatype,
    Actions,
    Action
} from "@app/components";
import {
    RequestProductItemModel,
    SubscriptionItemDisplayModel,
    MaterialRequestItemDisplayPropsModel,
    SubscriptionMaterialService
} from "@app/service/material";
import { StateType } from "@app/states";
import {
    createSubscriptionItem,
    updateSubscriptionItem,
    deleteSubscriptionItem4Class,
    deleteSubscriptionItem,
    processError
} from "@app/states/material";
import { RequestComponent } from "./request";
import {
    PurchaseTarget,
    GSSchoolAction,
    MaterialOrderStatus
} from "@app/util/enum";
import { ProductModal } from "./product";
import { SchoolClassModel } from "@app/service/class";
import { getUnitTextForLocalProduct } from "@app/page/product/components/unitprice";
import { Aggregate } from "wijmo/wijmo";
import { GroupRow, HeadersVisibility, FlexGrid } from "wijmo/wijmo.grid";
import { isFunction } from "lodash";
import { getUnitText } from "../util";

const { Grid, Column } = WijmoGrid;

@connect(({ material: { purchases, error } }: StateType) => ({ error }), {
    createRequestItem: createSubscriptionItem,
    updateRequestItem: updateSubscriptionItem,
    deleteRequestItem: deleteSubscriptionItem4Class
})
export class SubscriptionRequest extends RequestComponent {
    static defaultProps = {
        hideRequestItemEditAction: requestItem => {
            return (
                requestItem.status != MaterialOrderStatus.Initialized &&
                requestItem.status != MaterialOrderStatus.Draft
            );
        }
    };
    getColumns(): ColumnProps<SubscriptionItemDisplayModel>[] {
        const fmtMsg = GLGlobal.intl.formatMessage;
        let columns: ColumnProps<SubscriptionItemDisplayModel>[] = [
            {
                title: fmtMsg({
                    id: SchoolLocale.MaterialPurchaseItemFieldProduct
                }),
                dataIndex: MaterialRequestItemDisplayPropsModel.productName,
                width: this.props.canEdit ? "40%" : "50%"
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.MaterialPurchaseItemFieldUnit
                }),
                dataIndex: MaterialRequestItemDisplayPropsModel.unit,
                width: "10%",
                render: (text, { unit }, index) => (unit ? Math.abs(unit) : "")
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.MaterialPurchaseItemFieldQuantity
                }),
                dataIndex: MaterialRequestItemDisplayPropsModel.quantity,
                width: "10%"
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.MaterialSubscriptionItemFieldRequestBy
                }),
                dataIndex:
                    MaterialRequestItemDisplayPropsModel.requestedBy2Name,
                width: "15%"
            },
            {
                title: fmtMsg({
                    id: SchoolLocale.MaterialSubscriptionItemFieldRequestDate
                }),
                dataIndex:
                    MaterialRequestItemDisplayPropsModel.requestDateFormatted,
                width: "15%"
            }
        ];
        if (this.props.canEdit) {
            columns.push({
                title: "",
                width: "10%",
                render: (text, record, index) => {
                    if (record.status) {
                        return null;
                    } else {
                        return (
                            <div className="table-item-actions">
                                <GLIcon
                                    type="icon-edit"
                                    key={0}
                                    labelId={this.props.editRequestIconTitleId}
                                    onClick={() =>
                                        this.showRequestModal(record)
                                    }
                                />
                                <GLIcon
                                    type="icon-delete"
                                    key={1}
                                    labelId={
                                        this.props.deleteRequestIconTitleId
                                    }
                                    onClick={() =>
                                        this.handleDeleteRequestItem(record)
                                    }
                                />
                            </div>
                        );
                    }
                }
            });
        }
        return columns;
    }
    getRequestModalTitle(isNew: boolean) {
        return isNew
            ? SchoolLocale.MaterialRequestItemModalTitleNewClass
            : SchoolLocale.MaterialRequestItemModalTitleEditClass;
    }
    getRowClassName(record, index) {
        return record.status ? "submitted-row" : "";
    }
}

interface SubscriptionClassProps {
    canEdit?: boolean;
    canEditQuantity?: boolean;
    needShippedQuantityColumn?: boolean;
    clearShippedValue?: boolean;
    form?: WrappedFormUtils;
    locationParams: any;
    subscriptions?: SubscriptionItemDisplayModel[];
    error?: any;
    dataSource?: any;
    isGridEditing?: boolean;
    shippedQuantityIsRequired?: boolean;
    history?: H.History;
    needTotalRow?: boolean;
    stickyHeaders?: boolean;
    //onShippedQuantityChanged?: (grid, dataSource, eventData) => { valid: boolean; message: string };
    createSubscriptionItem?: (item) => void;
    updateSubscriptionItem?: (item) => void;
    deleteSubscriptionItem?: (item) => void;
    setGridRef?: (grid: FlexGrid) => void;
    showClassAsLink: boolean;
}

interface SubscriptionClassStates {
    modalVisible?: boolean;
    modalTitle?: string;
    requestItem?: RequestProductItemModel & SubscriptionItemDisplayModel;
    schoolClass?: SchoolClassModel;
    isCreate: boolean;
    isSaving?: boolean;
}

class SubscriptionClassTable extends GLEditableTable<any> { }

@connect(
    ({ material: { subscriptions, error } }: StateType) => ({
        subscriptions,
        error
    }),
    {
        createSubscriptionItem,
        updateSubscriptionItem,
        deleteSubscriptionItem: deleteSubscriptionItem
    }
)
export class SubscriptionClass extends Component<
SubscriptionClassProps,
SubscriptionClassStates
> {
    subscriptionService: SubscriptionMaterialService;
    static defaultProps = { showClassAsLink: true };
    constructor(props) {
        super(props);
        this.subscriptionService = new SubscriptionMaterialService();
        this.state = {
            modalVisible: false,
            modalTitle: SchoolLocale.MaterialRequestItemModalTitleNewClass,
            requestItem: {},
            isCreate: true,
            isSaving: false
        };
        this.showRequestModal = this.showRequestModal.bind(this);
        this.handleSaveRequestItem = this.handleSaveRequestItem.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.setRequestItem = this.setRequestItem.bind(this);
    }
    // getColumns() {
    //     const fmtMsg = GLGlobal.intl.formatMessage;
    //     const {needShippedQuantityColumn, clearShippedValue} = this.props;
    //     let columns: GLColumnProps<SubscriptionItemDisplayModel>[] = [
    //         {
    //             title: fmtMsg({ id: SchoolLocale.MaterialSubscriptionItemFieldClass }),
    //             dataIndex: MaterialRequestItemDisplayPropsModel.className,
    //             width: "10%",
    //             render: (text, record, index) => {
    //                 return this.props.canEdit ?
    //                     this.getColumnContent(text, record, index, record.rowSpan) :
    //                     this.getClassColumnContent(text, record, index, record.rowSpan);
    //             }
    //         },
    //         {
    //             title: fmtMsg({ id: SchoolLocale.MaterialSubscriptionItemFieldProduct }),
    //             dataIndex: MaterialRequestItemDisplayPropsModel.productName,
    //             width: this.props.canEdit ? "30%" : "26%",
    //             render: (text, record, index) => {
    //                 return this.getColumnContent(text, record, index, 1);
    //             }
    //         },
    //         {
    //             title: fmtMsg({ id: SchoolLocale.MaterialSubscriptionItemFieldUnit }),
    //             dataIndex: MaterialRequestItemDisplayPropsModel.unit,
    //             width: "10%",
    //             render: (text, record, index) => {
    //                 return this.getColumnContent(`${ record.unit ? Math.abs(record.unit) : ''}`, record, index, 1);
    //             }
    //         },
    //         {
    //             title: fmtMsg({ id: SchoolLocale.MaterialSubscriptionItemFieldQuantity }),
    //             dataIndex: MaterialRequestItemDisplayPropsModel.quantity,
    //             width: "10%",
    //             render: (text, record, index) => {
    //                 return this.getColumnContent(text, record, index, 1);
    //             }
    //         }
    //     ];
    //     if (needShippedQuantityColumn) {
    //         columns.push(
    //             {
    //                 title: fmtMsg({ id: SchoolLocale.MaterialOrderPageCommonFieldShippedQuantity }),
    //                 dataIndex: MaterialRequestItemDisplayPropsModel.shippedQuantity,
    //                 editable: true,
    //                 editType: EditableCellDatatype.number,
    //                 editorKeyName: MaterialRequestItemDisplayPropsModel.requestId,
    //                 editProps: {min:0, max:9999, precision:0, maxLength: 4},
    //                 clearValue: clearShippedValue,
    //                 width: "16%"
    //             }
    //         );
    //     }
    //     columns.push(...[
    //         {
    //             title: fmtMsg({ id: SchoolLocale.MaterialSubscriptionItemFieldRequestBy }),
    //             dataIndex: MaterialRequestItemDisplayPropsModel.requestedBy2Name,
    //             width: "15%",
    //             render: (text, record, index) => {
    //                 return this.getColumnContent(text, record, index, 1);
    //             }
    //         },
    //         {
    //             title: fmtMsg({ id: SchoolLocale.MaterialSubscriptionItemFieldRequestDate }),
    //             dataIndex: MaterialRequestItemDisplayPropsModel.requestDateFormatted,
    //             width: "15%",
    //             render: (text, record, index) => {
    //                 return this.getColumnContent(text, record, index, 1);
    //             }
    //         }
    //     ]);
    //     if (this.props.canEdit) {
    //         columns.push(
    //             {   title: "",
    //                 width: "8%",
    //                 render: (text, record, index) => {
    //                     return this.getOperationColumnContent(text, record, index, 1);
    //                 }
    //             });
    //     }
    //     else if (GLGlobal.isActionValid(GSSchoolAction.EditLicenseEntry)) {
    //         columns.push(
    //             {   title: fmtMsg({ id: SchoolLocale.LicenseHistoryTitle }),
    //                 width: "14%",
    //                 render: (text, record, index) => {
    //                     return this.getLicenseHistoryColumnContent(text, record, index);
    //                 }
    //             });
    //     }
    //     return columns;
    // }
    // getColumnContent(text: string, record: SubscriptionItemDisplayModel, index: number, rowSpan?: number) {
    //     const obj = {
    //         children: text,
    //         props: {rowSpan: rowSpan? rowSpan : record.rowSpan},
    //     };
    //     return obj;
    // }
    // getOperationColumnContent(text: string, record: SubscriptionItemDisplayModel, index: number, rowSpan?: number) {
    //     const obj = {
    //         children: (
    //             <div className='table-item-actions'>
    //                 <GLIcon type='icon-edit' key={0} labelId={SchoolLocale.MaterialSubscriptionItemListIconEdit} onClick={()=> this.showRequestModal(record)} />
    //                 <GLIcon type='icon-delete' key={1}  labelId={SchoolLocale.MaterialSubscriptionItemListIconDelete} onClick={()=> this.handleDeleteSubscriptionItem(record)} />
    //             </div>
    //         ),
    //         props: {rowSpan: rowSpan? rowSpan : record.rowSpan},
    //     };
    //     return obj;
    // }
    // getClassColumnContent(text: string, record: SubscriptionItemDisplayModel, index: number, rowSpan?: number) {
    //     const {regionId, schoolId, campusId} = this.props.locationParams;
    //     const obj = {
    //         children: (
    //             <Link to={GLUtil.pathStringify(PathConfig.Students, { regionId, schoolId, campusId, classId: record.classId })}>
    //                 {text}
    //             </Link>
    //         ),
    //         props: {rowSpan: rowSpan? rowSpan : record.rowSpan},
    //     };
    //     return obj;
    // }
    // getLicenseHistoryColumnContent(text: string, record: SubscriptionItemDisplayModel, index: number, rowSpan?: number) {
    //     const {regionId, schoolId, campusId} = this.props.locationParams;
    //     const obj = {
    //         children: (
    //             <div className='table-item-actions'>
    //                 <Link to={GLUtil.pathStringify(PathConfig.ClassLicenseHistory, { regionId, schoolId, campusId, classId: record.classId })}>
    //                     <GLIcon type='info-circle-o' key={0} labelId={SchoolLocale.LicenseHistoryTitle} />
    //                 </Link>
    //             </div>
    //         ),
    //         props: {rowSpan: rowSpan? rowSpan : record.rowSpan},
    //     };
    //     return obj;
    // }
    componentWillReceiveProps(nextProps) {
        if (this.props.subscriptions != nextProps.subscriptions) {
            this.setState({
                modalVisible: false,
                isSaving: false
            });
        }
        if (this.props.error != nextProps.error) {
            this.setState({ isSaving: false });
        }
    }
    async showRequestModal(item?: SubscriptionItemDisplayModel) {
        maskThrottle();
        try {
            const requestItem = item
                ? await this.subscriptionService.getSubscriptionItem({
                    requestId: item.id,
                    itemId: item.requestId
                })
                : {};
            const isNew = item == null;
            this.setState({
                modalVisible: true,
                modalTitle: isNew
                    ? SchoolLocale.MaterialRequestItemModalTitleNewClass
                    : SchoolLocale.MaterialRequestItemModalTitleEditClass,
                schoolClass: (isNew
                    ? {}
                    : {
                        id: item.classId,
                        name: item.className
                    }) as SchoolClassModel,
                isCreate: isNew,
                requestItem: isNew
                    ? {}
                    : {
                        ...requestItem,
                        requestId: item.id,
                        classId: item.classId
                    }
            });
            unmaskThrottle();
        } catch (response) {
            unmaskThrottle();
            const error =
                response.statusCode == HttpStatusCode.BadRequest &&
                    response.text
                    ? JSON.parse(response.text)
                    : null;
            processError(error);
        }
    }
    handleDeleteSubscriptionItem(
        subscriptionItem: SubscriptionItemDisplayModel
    ) {
        const fmtMsg = GLGlobal.intl.formatMessage;
        Modal.confirm({
            title: fmtMsg({
                id: SchoolLocale.MaterialSubscriptionItemListDeletePrompt
            }),
            okText: fmtMsg({ id: GLLocale.Ok }),
            cancelText: fmtMsg({ id: GLLocale.Cancel }),
            onOk: () => {
                this.props.deleteSubscriptionItem({
                    ...subscriptionItem,
                    requestId: subscriptionItem.id,
                    id: subscriptionItem.requestId,
                    ...this.props.locationParams
                });
            }
        });
    }
    // showRequestModal(item?: RequestProductItemModel) {
    //     this.setState({
    //         modalVisible: true,
    //         modalTitle: SchoolLocale.MaterialRequestItemModalTitleNewClass,
    //         requestItem: {}
    //     });
    // }
    handleSaveRequestItem(values) {
        const item = { ...values, id: this.state.requestItem.id }; //: {...values, requestId: this.props.requestId, id: this.state.requestItem.id};
        this.setState({ isSaving: true });
        this.state.isCreate
            ? this.props.createSubscriptionItem({
                ...values,
                ...this.props.locationParams
            })
            : this.props.updateSubscriptionItem({
                ...item,
                ...this.props.locationParams
            });
    }
    // handleSaveRequestItem(values) {
    //     this.setState({isSaving: true});
    //     this.props.createSubscriptionItem({...values, ...this.props.locationParams});
    // }
    handleCancel() {
        this.setState({ modalVisible: false });
    }
    setRequestItem(item) {
        this.setState({ requestItem: item });
    }
    gridInitialized = flex => {
        if (isFunction(this.props.setGridRef)) {
            this.props.setGridRef(flex);
        }
        if (this.props && this.props.needTotalRow) {
            flex.columnFooters.rows.push(new GroupRow());
            flex.bottomLeftCells.setCellData(0, 0, "Total");
        }
    };
    render() {
        const fmtMsg = GLGlobal.intl.formatMessage;
        const {
            subscriptions,
            isGridEditing,
            needShippedQuantityColumn,
            canEdit,
            locationParams,
            dataSource,
            history,
            stickyHeaders,
            needTotalRow,
            showClassAsLink
        } = this.props;
        const handlers = {
            onSubmit: this.handleSaveRequestItem,
            onCancel: this.handleCancel
        };
        const params = { ...locationParams, class: this.state.schoolClass };
        const requestListClassName = {
            "material-request-grid": true,
            "edit-mode": isGridEditing
        };
        const shippedQuantityClassName = {
            "shipped-quantity-column": true,
            "edit-mode": isGridEditing
        };

        return (
            <div className="material-subscription">
                <div className="list-header">
                    <h3>
                        {fmtMsg({
                            id: SchoolLocale.MaterialSubscriptionItemListTitle
                        })}
                    </h3>
                    {canEdit && (
                        <Actions>
                            <Action
                                materialIcon="add"
                                textLocaleId={
                                    SchoolLocale.MaterialRequestItemNewClass
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    this.showRequestModal();
                                }}
                            />
                        </Actions>
                    )}
                    <ProductModal
                        {...this.state}
                        purchaseTarget={PurchaseTarget.SchoolClass}
                        dataParams={params}
                        {...handlers}
                    />
                </div>
                <Grid
                    stickyHeaders={
                        typeof stickyHeaders === "boolean"
                            ? stickyHeaders
                            : true
                    }
                    itemsSource={subscriptions}
                    isReadOnly={!isGridEditing}
                    className={classNames(requestListClassName)}
                    initialized={this.gridInitialized}
                    headersVisibility={
                        needTotalRow
                            ? HeadersVisibility.All
                            : HeadersVisibility.Column
                    }
                //cellEditEnded={(grid, event) => onShippedQuantityChanged(grid, dataSource, event)}
                >
                    <Column
                        binding={MaterialRequestItemDisplayPropsModel.className}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldClass
                        })}
                        render={(value, item) => {
                            const {
                                regionId,
                                schoolId,
                                campusId
                            } = locationParams;
                            return !showClassAsLink || canEdit || !item.classId ? (
                                value
                            ) : (
                                <ColumnLink
                                    history={history}
                                    url={GLUtil.pathStringify(
                                        PathConfig.Students,
                                        {
                                            regionId,
                                            schoolId,
                                            campusId,
                                            classId: item.classId
                                        }
                                    )}
                                >
                                    {value}
                                </ColumnLink>
                            );
                        }}
                    />
                    <Column
                        binding={
                            MaterialRequestItemDisplayPropsModel.productName
                        }
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldProduct
                        })}
                    />
                    <Column
                        align="left"
                        binding={MaterialRequestItemDisplayPropsModel.unitText}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldUnit
                        })}
                    />
                    <Column
                        binding={MaterialRequestItemDisplayPropsModel.quantity}
                        header={fmtMsg({
                            id: SchoolLocale.MaterialPurchaseItemFieldQuantity
                        })}
                        aggregate={Aggregate.Sum}
                    />
                    {needShippedQuantityColumn && (
                        <Column
                            binding={
                                MaterialRequestItemDisplayPropsModel.shippedQuantity
                            }
                            header={fmtMsg({
                                id:
                                    SchoolLocale.MaterialOrderPageCommonFieldShippedQuantity
                            })}
                            cssClass={classNames(shippedQuantityClassName)}
                            isReadOnly={false}
                            renderEditor={(context) => <GridInputNumber isRequired={false} context={context} min={0} max={9999} format="n0" />}
                            align="right"
                            aggregate={Aggregate.Sum}
                        />
                    )}
                    <Column
                        binding={
                            MaterialRequestItemDisplayPropsModel.requestedBy2Name
                        }
                        header={fmtMsg({
                            id:
                                SchoolLocale.MaterialSubscriptionItemFieldRequestBy
                        })}
                    />
                    <Column
                        binding={
                            MaterialRequestItemDisplayPropsModel.requestDateFormatted
                        }
                        header={fmtMsg({
                            id:
                                SchoolLocale.MaterialSubscriptionItemFieldRequestDate
                        })}
                    />
                    {canEdit && (
                        <Column
                            allowSorting={false}
                            render={(value, item) => {
                                return (
                                    <div className="table-item-actions">
                                        <GLIcon
                                            type="icon-edit"
                                            key={0}
                                            labelId={
                                                SchoolLocale.MaterialSubscriptionItemListIconEdit
                                            }
                                            onClick={() =>
                                                this.showRequestModal(item)
                                            }
                                        />
                                        <GLIcon
                                            type="icon-delete"
                                            key={1}
                                            labelId={
                                                SchoolLocale.MaterialSubscriptionItemListIconDelete
                                            }
                                            onClick={() =>
                                                this.handleDeleteSubscriptionItem(
                                                    item
                                                )
                                            }
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                    {!canEdit &&
                        GLGlobal.isActionValid(
                            GSSchoolAction.EditLicenseEntry
                        ) && (
                            <Column
                                allowSorting={false}
                                binding={
                                    MaterialRequestItemDisplayPropsModel.licenseHistory
                                }
                                header={fmtMsg({
                                    id: SchoolLocale.LicenseHistoryTitle
                                })}
                                render={(value, item) => {
                                    const {
                                        regionId,
                                        schoolId,
                                        campusId
                                    } = this.props.locationParams;
                                    return (
                                        <ColumnLink
                                            history={history}
                                            url={GLUtil.pathStringify(
                                                PathConfig.ClassLicenseHistory,
                                                {
                                                    regionId,
                                                    schoolId,
                                                    campusId,
                                                    classId: item.classId
                                                }
                                            )}
                                        >
                                            <GLIcon
                                                type="info-circle-o"
                                                key={0}
                                                labelId={
                                                    SchoolLocale.LicenseHistoryTitle
                                                }
                                            />
                                        </ColumnLink>
                                    );
                                }}
                            />
                        )}
                </Grid>
            </div>
        );
    }
}
