import * as React from "react";
import { Component } from "react";
import { GLIcon } from "../../../../components";
import { SubscriptionItemDisplayModel } from "../../../../service/material";
import { connect } from "gl-commonui";
import { SchoolLocale } from "../../../../locales/localeid";
import { StateType } from "@app/states";
import {
    createPurchaseItem,
    updatePurchaseItem,
    deletePurchaseItem,
    createPurchaseItem4Class,
    updatePurchaseItem4Class,
    deletePurchaseItem4Class
} from "../../../../states/material";
import { RequestComponent } from "./request";
import { MaterialOrderStatus } from "@app/util";

@connect(({ material: { purchases, error } }: StateType) => ({ error }), {
    createRequestItem: createPurchaseItem,
    updateRequestItem: updatePurchaseItem,
    deleteRequestItem: deletePurchaseItem
})
export class PurchaseRequest extends RequestComponent {
    getRequestModalTitle(isNew: boolean) {
        return isNew
            ? SchoolLocale.MaterialRequestItemModalTitleNew
            : SchoolLocale.MaterialRequestItemModalTitleEdit;
    }
}

@connect(({ material: { purchases, error } }: StateType) => ({ error }), {
    createRequestItem: createPurchaseItem4Class,
    updateRequestItem: updatePurchaseItem4Class,
    deleteRequestItem: deletePurchaseItem4Class
})
export class PurchaseRequest4Class extends RequestComponent {
    static defaultProps = {
        hideRequestItemEditAction: requestItem => {
            return (
                requestItem.status != MaterialOrderStatus.Initialized &&
                requestItem.status != MaterialOrderStatus.Draft
            );
        }
    };
    getRequestModalTitle(isNew: boolean) {
        return isNew
            ? SchoolLocale.MaterialRequestItemModalTitleNew
            : SchoolLocale.MaterialRequestItemModalTitleEdit;
    }
    getRowClassName(record, index) {
        return record.status ? "submitted-row" : "";
    }
    getOperationColumnContent(
        text: string,
        record: SubscriptionItemDisplayModel,
        index: number,
        rowSpan?: number
    ) {
        const obj = {
            children: this.getOperationElement(record),
            props: { rowSpan: 1 }
        };
        return obj;
    }
    getOperationElement(record: SubscriptionItemDisplayModel) {
        if (record.status) {
            return null;
        } else {
            return (
                <div className="table-item-actions">
                    <GLIcon
                        type="icon-edit"
                        key={0}
                        labelId={this.props.editRequestIconTitleId}
                        onClick={() => this.showRequestModal(record)}
                    />
                    <GLIcon
                        type="icon-delete"
                        key={1}
                        labelId={this.props.deleteRequestIconTitleId}
                        onClick={() => this.handleDeleteRequestItem(record)}
                    />
                </div>
            );
        }
    }
}
